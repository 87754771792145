export enum SmallIconNames {
  arrowDiagonal = "arrow-diagonal",
  arrowLeft = "arrow-left",
  arrowRight = "arrow-right",
  arrowUp = "arrow-up",
  chevronDown = "chevron-down",
  chevronLeft = "chevron-left",
  chevronRight = "chevron-right",
  chevronUp = "chevron-up",
  collapse = "collapse",
  compare = "compare",
  expand = "expand",
  leftRight = "left-right",
  maximize = "maximize",
  reply = "reply",
  reset = "reset",
  stepBack = "step-back",
  upDown = "up-down",
  alertCircle = "alert-circle",
  alertOutline = "alert-outline",
  alert = "alert",
  asterisk = "asterisk",
  attachment = "attachment",
  awning = "awning",
  basketAdd = "basket-add",
  basket = "basket",
  bathFurniture = "bath-furniture",
  bath = "bath",
  box = "box",
  bricks = "bricks",
  briefcase = "briefcase",
  bulb = "bulb",
  calendarPlant = "calendar-plant",
  cancel = "cancel",
  car = "car",
  category = "category",
  checkCircleFilled = "check-circle-filled",
  checkCircleOutline = "check-circle-outline",
  check = "check",
  closeCircleFilled = "close-circle-filled",
  closeCircleOutline = "close-circle-outline",
  close = "close",
  color = "color",
  copy = "copy",
  dashboard = "dashboard",
  delete = "delete",
  discountFilled = "discount-filled",
  discountSticker = "discount-sticker",
  distance = "distance",
  download = "download",
  edit = "edit",
  file = "file",
  filter = "filter",
  folder = "folder",
  glasses = "glasses",
  globe = "globe",
  glove = "glove",
  hammer = "hammer",
  happy = "happy",
  hashtag = "hashtag",
  headphones = "headphones",
  helmet = "helmet",
  home = "home",
  house = "house",
  info = "info",
  ladle = "ladle",
  lamp = "lamp",
  layoutView = "layout-view",
  leaf = "leaf",
  link = "link",
  listView = "list-view",
  loader = "loader",
  logout = "logout",
  mask = "mask",
  menuBurger = "menu-burger",
  menuHorizontal = "menu-horizontal",
  menuVertical = "menu-vertical",
  minusCircleFilled = "minus-circle-filled",
  minusCircleOutline = "minus-circle-outline",
  minus = "minus",
  navigation = "navigation",
  neutral = "neutral",
  paint = "paint",
  pathTool = "path-tool",
  people = "people",
  pin = "pin",
  plant = "plant",
  playButton = "play-button",
  plug = "plug",
  plusCircleFilled = "plus-circle-filled",
  plusCircleOutline = "plus-circle-outline",
  plus = "plus",
  pot = "pot",
  print = "print",
  profile = "profile",
  ratherHappy = "rather-happy",
  ratherUnhappy = "rather-unhappy",
  reorder = "reorder",
  role = "role",
  ruler = "ruler",
  save = "save",
  saw = "saw",
  search = "search",
  settings = "settings",
  shape1 = "shape-1",
  shape2 = "shape-2",
  shape3 = "shape-3",
  shareMobile = "share-mobile",
  shirt = "shirt",
  shoe = "shoe",
  shower = "shower",
  sink = "sink",
  tasks = "tasks",
  thickness = "thickness",
  tips = "tips",
  trailerHitch = "trailer-hitch",
  trailer = "trailer",
  trophy = "trophy",
  unhappy = "unhappy",
  unknown = "unknown",
  uploads = "uploads",
  wateringcan = "wateringcan",
  wc = "wc",
  wrench = "wrench",
  direction = "direction",
  locationDisabled = "location-disabled",
  location = "location",
  maps = "maps",
  storePickup = "store-pickup",
  store = "store",
  callBack = "call-back",
  callEnded = "call-ended",
  callOpen = "call-open",
  cameraSwitch = "camera-switch",
  camera = "camera",
  dislike = "dislike",
  expert = "expert",
  facebook = "facebook",
  favoriteFilled = "favorite-filled",
  favorite = "favorite",
  imageGallery = "image-gallery",
  inbox = "inbox",
  instagram = "instagram",
  like = "like",
  mail = "mail",
  message = "message",
  microphopneInactive = "microphopne-inactive",
  microphopne = "microphopne",
  note = "note",
  notification = "notification",
  pinterest = "pinterest",
  play = "play",
  ratingStarOutline = "rating-star-outline",
  ratingStar = "rating-star",
  send = "send",
  share = "share",
  videoInactive = "video-inactive",
  video = "video",
  viewInactive = "view-inactive",
  view = "view",
  youtube = "youtube",
  colorMixService = "color-mix-service",
  cuttingService = "cutting-service",
  delivery = "delivery",
  toolRental = "tool-rental",
  transportRental = "transport-rental",
  currency = "currency",
  discountCoupon = "discount-coupon",
  obiCard = "obi-card",
  packageDelivery = "package-delivery",
  package = "package",
  scanBot = "scan-bot",
  secure = "secure",
  shoppingBasket = "shopping-basket",
  shoppingListAdd = "shopping-list-add",
  shoppingListAdded = "shopping-list-added",
  shoppingList = "shopping-list",
  truck = "truck",
  van = "van",
  calendarAvailability = "calendar-availability",
  calendarLocation = "calendar-location",
  calendarVideo = "calendar-video",
  calendar = "calendar",
  history = "history",
  saveTime = "save-time",
  timeOutline = "time-outline",
  timer = "timer",
}

export enum MediumIconName {
  appointment = "appointment",
  biberBonus = "biber-bonus",
  careCalendar = "care-calendar",
  company = "company",
  coupons = "coupons",
  customMade = "custom-made",
  customerCard = "customer-card",
  customerCenterDashboard = "customer-center-dashboard",
  deliveryAddress = "delivery-address",
  discount = "discount",
  employee = "employee",
  expert = "expert",
  guarantee = "guarantee",
  mail = "mail",
  marketNavigation = "market-navigation",
  message = "message",
  newsletter = "newsletter",
  notes = "notes",
  order = "order",
  productScanner = "product-scanner",
  productSearch = "product-search",
  projectList = "project-list",
  setting = "setting",
  shoppingBasket = "shopping-basket",
  shoppingList = "shopping-list",
  store = "store",
}

export enum LargeIconNames {
  appointment = "appointment",
  bonuses = "bonuses",
  careplan = "careplan",
  errorAppointment = "error-appointment",
  errorBonuses = "error-bonuses",
  errorCareplan = "error-careplan",
  errorMessage = "error-message",
  errorRegister = "error-register",
  infoAppointment = "info-appointment",
  infoRegister = "info-register",
  message = "message",
  register = "register",
  shopping = "shopping",
  shoppinglist = "shoppinglist",
  successAppointment = "success-appointment",
  successBonuses = "success-bonuses",
  successCareplan = "success-careplan",
  successMessage = "success-message",
  successRegister = "success-register",
  activatePush = "activate-push",
  delete = "delete",
  documentUploadError = "document-upload-error",
  documentUpload = "document-upload",
  error = "error",
  missingImage = "missing-image",
  searchResult = "search-result",
  thumbsup = "thumbsup",
  warning = "warning",
  balcony = "balcony",
  flat = "flat",
  garden = "garden",
  house = "house",
  bathroom = "bathroom",
  build = "build",
  gardenHobby = "garden-hobby",
  kitchen = "kitchen",
  living = "living",
  technic = "technic",
}
