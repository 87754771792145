import { defineComponent, h, PropType } from "vue";
import { MediumIconName } from "@/components/Icons/icon-names";

export default defineComponent({
  name: "SelfIcon",
  props: {
    size: {
      type: Number as PropType<number>,
      default: 40,
    },
    name: {
      type: String as PropType<MediumIconName>,
      required: true,
    },
  },
  setup(props) {
    return () =>
      h(
        "svg",
        {
          width: props.size,
          height: props.size,
          viewBox: "0 0 40 40",
          xmlns: "http://www.w3.org/2000/svg",
        },
        [
          h("use", {
            href: `/assets/icons/m/${props.name}.svg#${props.name}`,
          }),
        ]
      );
  },
});
