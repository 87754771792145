import { MediumIconName } from "@/components/Icons/icon-names";
import { istFeatureAktiviertFuer } from "@/utils/static-features-flags";

export enum NavigationItemKey {
  Uebersicht = "uebersicht",
  Postfach = "postfach",
  Einkaeufe = "einkaeufe",
  BiberBonus = "biberBonus",
  Merkzettel = "merkzettel",
  Vorteile = "vorteile",
  Pflegekalender = "pflegekalender",
  MeinMarkt = "meinMarkt",
  PersoenlicheDaten = "persoenlicheDaten",
  Lieferanschriften = "lieferanschriften",
  Personalisierung = "personalisierung",
  Termine = "termine",
  Newsletter = "newsletter",
}

export declare type Country =
  | "de"
  | "at"
  | "pl"
  | "ch"
  | "hu"
  | "cz"
  | "sk"
  | "it";

export declare type NavigationItemType = {
  key: NavigationItemKey;
  translationKey: string;
  url: string;
  icon: MediumIconName;
  countries: Country[];
};

const environment = process.env.VUE_APP_ENVIRONMENT;

function getLanguagePrefix(locale: string) {
  switch (locale) {
    case "fr-CH":
      return "/fr";
    case "it-CH":
      return "/it";
    default:
      return "";
  }
}

function getNewsletterLanguagePrefix(locale: string) {
  switch (locale) {
    case "fr-CH":
      return "/fr";
    case "it-CH":
      return "/it";
    case "de-CH":
      return "/de";
    default:
      return "";
  }
}

export function getNavigationItems(locale = "de-DE") {
  const featureAndLocalesBasedItems: NavigationItemType[] = [
    {
      key: NavigationItemKey.Uebersicht,
      translationKey: "navigation.uebersicht",
      url: ["pl-PL", "de-CH", "it-CH", "fr-CH", "hu-HU", "it-IT"].includes(
        locale
      )
        ? `${getLanguagePrefix(locale)}/customer-account`
        : "/heyobi/customer-center/dashboard",
      icon: MediumIconName.customerCenterDashboard,
      countries: ["de", "at", "pl", "ch", "hu", "sk", "cz", "it"],
    },
    {
      key: NavigationItemKey.Postfach,
      translationKey: "navigation.postfach",
      url: "/heyobi/customer-center/inbox",
      icon: MediumIconName.message,
      countries: ["de", "at"],
    },
  ];

  if (istFeatureAktiviertFuer("termineLinkAusspielen", environment)) {
    featureAndLocalesBasedItems.push({
      key: NavigationItemKey.Termine,
      translationKey: "navigation.termine",
      url: "/heyobi/customer-center/appointments",
      icon: MediumIconName.appointment,
      countries: ["de", "at"],
    });
  }

  featureAndLocalesBasedItems.push({
    key: NavigationItemKey.Einkaeufe,
    translationKey: "navigation.einkaeufe",
    url: `${getLanguagePrefix(locale)}/customer-account/orders`,
    icon: MediumIconName.shoppingBasket,
    countries: ["de", "at", "pl", "ch", "hu", "sk", "cz", "it"],
  });

  if (istFeatureAktiviertFuer("biberBonusLinkAusspielen", environment)) {
    featureAndLocalesBasedItems.push({
      key: NavigationItemKey.BiberBonus,
      translationKey: "navigation.biberBonus",
      url: "/heyobi/bonus-booklet/dddd845d-454b-477c-a09e-0bbeee259dd2/dashboard",
      icon: MediumIconName.biberBonus,
      countries: ["de"],
    });
    featureAndLocalesBasedItems.push({
      key: NavigationItemKey.BiberBonus,
      translationKey: "navigation.biberBonus",
      url: "/heyobi/bonus-booklet/aaaa02ff-9c27-43a1-bdce-db17b51137cc/dashboard",
      icon: MediumIconName.biberBonus,
      countries: ["at"],
    });
  }

  featureAndLocalesBasedItems.push(
    {
      key: NavigationItemKey.Merkzettel,
      translationKey: "navigation.merkzettel",
      url: `${getLanguagePrefix(locale)}/shopping-list`,
      icon: MediumIconName.notes,
      countries: ["de", "at", "pl", "ch", "hu", "sk", "cz", "it"],
    },
    {
      key: NavigationItemKey.Vorteile,
      translationKey: "navigation.vorteile",
      url: "/heyobi/customer-center/benefits",
      icon: MediumIconName.discount,
      countries: ["de", "at"],
    },
    {
      key: NavigationItemKey.Pflegekalender,
      translationKey: "navigation.pflegekalender",
      url: "/heyobi/customer-center/care-calendar",
      icon: MediumIconName.careCalendar,
      countries: ["de", "at"],
    },
    {
      key: NavigationItemKey.MeinMarkt,
      translationKey: "navigation.meinMarkt",
      url: `${getLanguagePrefix(locale)}/customer-account/store`,
      icon: MediumIconName.store,
      countries: ["de", "at", "pl", "ch", "hu", "sk", "cz", "it"],
    },
    {
      key: NavigationItemKey.PersoenlicheDaten,
      translationKey: "navigation.persoenlicheDaten",
      url: `${getLanguagePrefix(locale)}/customer-account/personal-data`,
      icon: MediumIconName.employee,
      countries: ["de", "at", "pl", "ch", "hu", "sk", "cz", "it"],
    },
    {
      key: NavigationItemKey.Lieferanschriften,
      translationKey: "navigation.lieferanschriften",
      url: `${getLanguagePrefix(locale)}/customer-account/delivery-address`,
      icon: MediumIconName.deliveryAddress,
      countries: ["de", "at", "pl", "hu", "sk", "cz", "it"],
    },
    {
      key: NavigationItemKey.Personalisierung,
      translationKey: "navigation.personalisierung",
      url: "/heyobi/customer-center/preference-center",
      icon: MediumIconName.setting,
      countries: ["de", "at"],
    }
  );

  featureAndLocalesBasedItems.push({
    key: NavigationItemKey.Newsletter,
    translationKey: "navigation.newsletter",
    url: `${getNewsletterLanguagePrefix(locale)}/newsletter`,
    icon: MediumIconName.newsletter,
    countries: ["pl", "ch", "hu", "sk", "cz", "it"],
  });

  const land = locale.split("-")[1].toLowerCase() as Country;

  return featureAndLocalesBasedItems.filter((item) =>
    item.countries.includes(land)
  );
}
