
import { computed, defineComponent, onMounted, ref } from "vue";
import { PropType } from "@vue/runtime-core";
import {
  NavigationItemKey,
  getNavigationItems,
  NavigationItemType,
} from "@/components/Navigation/NavigationItems";
import SmallIcon from "@/components/Icons/SmallIcon";
import MediumIcon from "@/components/Icons/MediumIcon";
import { useI18n } from "@/i18n";
import { AccountType } from "@/components/Navigation/AccountType";
import { SmallIconNames } from "@/components/Icons/icon-names";

export default defineComponent({
  name: "KundenkontoNavigation",
  computed: {
    SmallIconNames() {
      return SmallIconNames;
    },
  },
  components: { SmallIcon, MediumIcon },
  props: {
    activeItem: {
      type: String as PropType<NavigationItemKey>,
    },
    locale: {
      type: String as PropType<string>,
      default: "de-DE",
    },
    accountType: {
      type: String as PropType<AccountType>,
      default: AccountType.OBI_CUSTOMER,
    },
  },
  emits: ["self-kundenkonto-navigation-link-click"],
  setup(props) {
    const { t } = useI18n(props.locale);
    const target = ref();
    const isSticking = ref(false);
    const istAusgeklappt = ref(false);
    const navigationItems = ref(getNavigationItems(props.locale));

    const observer = new IntersectionObserver(
      ([entry]) => {
        isSticking.value = !entry.isIntersecting;
      },
      { threshold: 1.0 }
    );

    onMounted(() => {
      observer.observe(target.value);
    });

    const istAngemeldet = computed(() => {
      return [
        AccountType.OBI_CUSTOMER,
        AccountType.HEYOBI_CUSTOMER,
        AccountType.HEYOBI_PRO_CUSTOMER,
      ].includes(props.accountType);
    });

    const activeItemData = computed(() => {
      return getNavigationItems(props.locale).find(
        (item) => item.key === props.activeItem
      );
    });

    return {
      t,
      target,
      isSticking,
      navigationItems,
      istAusgeklappt,
      istAngemeldet,
      activeItemData,
    };
  },
  methods: {
    onLinkClick(e: Event, navigationItem: NavigationItemType) {
      this.istAusgeklappt = false;
      this.$el.dispatchEvent(
        new CustomEvent("self-kundenkonto-navigation-link-click", {
          detail: {
            originalEvent: e,
            navigationItem: {
              key: navigationItem.key,
              url: navigationItem.url,
            },
          },
          bubbles: true,
          composed: true,
        })
      );
    },
  },
});
