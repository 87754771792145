import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-flex tw-items-center tw-gap-2 tw-text-4xl"
}
const _hoisted_2 = { class: "tw-grid tw-pb-6 lg:tw-py-6 tw-gap-4 tw-grid-cols-1" }
const _hoisted_3 = ["href", "onClick", "title"]
const _hoisted_4 = { class: "tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap" }
const _hoisted_5 = {
  key: 0,
  class: "tw-mx-6 tw-border-gray-400"
}
const _hoisted_6 = {
  key: 1,
  class: "tw-flex tw-align-center tw-leading-normal tw-cursor-pointer tw-py-4",
  "data-ui-name": "self-navigation-logout"
}
const _hoisted_7 = { class: "tw-px-6 tw-flex tw-items-center tw-flex-auto navigation-item" }
const _hoisted_8 = {
  href: "/account/logout",
  class: "navigation-item-link tw-leading-normal tw-cursor-pointer tw-text-gray-900"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MediumIcon = _resolveComponent("MediumIcon")!
  const _component_SmallIcon = _resolveComponent("SmallIcon")!

  return (_openBlock(), _createElementBlock("div", {
    "data-ui-name": "self-navigation",
    ref: "target",
    class: _normalizeClass(["tw-w-full tw-font-obi tw-text-lg tw-sticky lg:tw-bg-white tw--top-0.5 tw-overflow-hidden tw-z-10 lg:tw-z-0 lg:tw-static lg:tw-shadow-md lg:tw-rounded", { 'tw-bg-white': _ctx.isSticking, 'tw-shadow-lg': _ctx.isSticking }]),
    id: "main"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tw-px-4 tw-whitespace-nowrap tw-font-obi-bold lg:tw-hidden tw-flex tw-justify-between tw-items-center tw-cursor-pointer", _ctx.$style.navigationHeader]),
      "data-ui-name": "self-navigation-mobile-header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.istAusgeklappt = !_ctx.istAusgeklappt))
    }, [
      (_ctx.activeItemData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MediumIcon, {
              name: _ctx.activeItemData.icon,
              size: 40,
              class: "tw-inline"
            }, null, 8, ["name"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.activeItemData.translationKey)), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_SmallIcon, {
        name: 
          _ctx.istAusgeklappt ? _ctx.SmallIconNames.chevronUp : _ctx.SmallIconNames.chevronDown
        ,
        size: 18,
        class: "tw-inline"
      }, null, 8, ["name"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tw-max-h-0 lg:tw-max-h-full", {
        [_ctx.$style.navigationCollapsable]: true,
        [_ctx.$style.navigationCollapsableOpened]: _ctx.istAusgeklappt,
      }]),
      style: _normalizeStyle(_ctx.istAusgeklappt ? `max-height: 1000px` : ``)
    }, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (navigationItem) => {
          return (_openBlock(), _createElementBlock("li", {
            key: navigationItem.key,
            "data-ui-name": "self-navigation-item",
            class: _normalizeClass(["navigation-item tw-pl-4 tw-border-l-4", {
            'tw-border-transparent': navigationItem.key !== String(_ctx.activeItem),
            'tw-border-orange tw-font-obi-bold':
              navigationItem.key === String(_ctx.activeItem),
          }])
          }, [
            _createElementVNode("a", {
              href: navigationItem.url,
              class: "navigation-item-link tw-flex tw-items-center tw-leading-normal tw-cursor-pointer",
              onClick: (e) => _ctx.onLinkClick(e, navigationItem),
              title: _ctx.t(navigationItem.translationKey)
            }, [
              _createVNode(_component_MediumIcon, {
                class: "tw-flex-none tw-mr-6",
                name: navigationItem.icon
              }, null, 8, ["name"]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(navigationItem.translationKey)), 1)
            ], 8, _hoisted_3)
          ], 2))
        }), 128))
      ]),
      (_ctx.istAngemeldet)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.istAngemeldet)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.t("navigation.abmelden")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 6)
  ], 2))
}