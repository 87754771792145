declare type EnvironmentToggles = {
  local: boolean;
  lab: boolean;
  qa: boolean;
  prod: boolean;
};

declare type StaticFeatureToggle =
  | "termineLinkAusspielen"
  | "biberBonusLinkAusspielen";

const staticToggles: Record<StaticFeatureToggle, EnvironmentToggles> = {
  termineLinkAusspielen: {
    local: true,
    lab: true,
    qa: true,
    prod: true,
  },
  biberBonusLinkAusspielen: {
    local: true,
    lab: true,
    qa: true,
    prod: true,
  },
};

export function istFeatureAktiviertFuer(
  feature: StaticFeatureToggle,
  environment: keyof EnvironmentToggles
): boolean {
  return staticToggles[feature][environment];
}
