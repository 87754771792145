import deDE from "@/i18n/locales/de-DE.json";
import deAT from "@/i18n/locales/de-AT.json";
import plPL from "@/i18n/locales/pl-PL.json";
import deCH from "@/i18n/locales/de-CH.json";
import itCH from "@/i18n/locales/it-CH.json";
import frCH from "@/i18n/locales/fr-CH.json";
import huHU from "@/i18n/locales/hu-HU.json";
import csCZ from "@/i18n/locales/cs-CZ.json";
import skSK from "@/i18n/locales/sk-SK.json";
import itIT from "@/i18n/locales/it-IT.json";
import { get } from "lodash-es";

const messages = {
  "de-DE": { navigation: deDE },
  "de-AT": { navigation: deAT },
  "pl-PL": { navigation: plPL },
  "de-CH": { navigation: deCH },
  "it-CH": { navigation: itCH },
  "fr-CH": { navigation: frCH },
  "hu-HU": { navigation: huHU },
  "cs-CZ": { navigation: csCZ },
  "sk-SK": { navigation: skSK },
  "it-IT": { navigation: itIT },
};

type i18N = {
  t: (key: string) => string;
};

export function useI18n(locale: string): i18N {
  function t(key: string): string {
    return get(messages, `${locale}.${key}`);
  }

  return {
    t,
  };
}
