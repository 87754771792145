// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".self-navigationHeader-ee1b0{height:66px}.self-navigationCollapsable-bcc02{transition:max-height .25s ease-out}.self-navigationCollapsableOpened-e4350{max-height:1500px;transition:max-height .25s ease-in}li.navigation-item:has(a.navigation-item-link:focus){outline:1px solid #0166e9;outline-offset:-1px}div.navigation-item:has(a.navigation-item-link:focus){outline:1px solid #0166e9;outline-offset:-1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationHeader": "self-navigationHeader-ee1b0",
	"navigationCollapsable": "self-navigationCollapsable-bcc02",
	"navigationCollapsableOpened": "self-navigationCollapsableOpened-e4350"
};
export default ___CSS_LOADER_EXPORT___;
